import React from "react";
import Helmet from 'react-helmet';

/**
 * Layouts
 */
import Layout from '@layouts/default';

/**
 * Components
 */
// import Map from '@components/map/map';
// import GoogleMap from "@components/map/google-map";
import LocRatingMap from "@components/map/loc-rating-map";
// import BingMap from "@components/map/bing-map";
// import Offices from "@components/offices/offices";

const MapPage = () => {
  let mapItems = [];
  return (
    <Layout>
      <Helmet 
        bodyAttributes={{
          class: 'map-page'
        }}
      />
      {/* <Map /> */}
      {/* <GoogleMap data={mapItems} /> */}
      {/* <LocRatingMap data={mapItems} /> */}
      <LocRatingMap data={mapItems} />
      {/* <BingMap data={mapItems} /> */}
    </Layout>
  );
}

export default MapPage;